import './Plugin.css';
import { Calendar } from "@omega365design/react-components";
import React from 'react';

function Modal({ isOpen, children, onClose }) {
  return (
    <>
    {isOpen && <div className="modal">
      <div className="overlay"></div>
      <div className="children">{children}</div>
    </div>}
    </>
  );
}

export default Modal;

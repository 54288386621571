import JelsaPlugin from "./JelsaPlugin";

window.JelsaPlugin = JelsaPlugin;

if (process.env.NODE_ENV == "development") {
  let options = {
    date: new Date(),
    rooms: [
      {
        "name": "Dobbeltrom",
        "id": "130426"
      },
      {
        "name": "Juniorsuite",
        "id": "130427"
      },
      {
        "name": "Twinrom",
        "id": "130428"
      },
      {
        "name": "Universalrom",
        "id": "130429"
      }
    ],
  }

  let plugin = new JelsaPlugin(options);
  plugin.render();

  console.log(plugin)
}
import React from 'react';
import ReactDOM from 'react-dom';
import Plugin from './Plugin';

export default class JelsaPlugin {
  constructor({ date, rooms }) {
    this.date = date;
    this.rooms = rooms;
  }

  render() {
    ReactDOM.render(
      <React.StrictMode>
        <Plugin {...this} />
      </React.StrictMode>,
      document.getElementById('jelsa-booking-plugin')
    );
  }
}
import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "@omega365design/react-components";
import styled from "styled-components";
import {
  isSameMonth,
  format,
  isBefore,
  addMonths,
  isAfter,
  isSameDay,
  differenceInDays,
} from "date-fns";
import { useWindowSize } from "@react-hook/window-size";

let Position = styled.div`
  display: flex;
  background-color: #f5f4ee;
  flex-direction: column;
  ${(props) => !props.isMobile && "position: absolute;"}
  margin-top: ${(props) => (props.isMobile ? "0px" : "-490px")};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border: 2px solid #8f6060 !important;
`;

let CalendarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

function DoubleCalendar({ state, dispatch, onClick }) {
  let [width, _] = useWindowSize();
  let ref = useRef();

  let isMobile = width <= 700;

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch({ type: "closeCalendar" });
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  function cellComponentFrom({ cellDate, isSelected, isBetween }) {
    let handleCellClick = () => {
      if (state.from && state.to) {
        dispatch({ type: "setCurrentField", payload: "fromField" });
        dispatch({ type: "setFrom", payload: null });
        dispatch({ type: "setTo", payload: null });
      } else if (state.from && isBefore(state.from, cellDate)) {
        dispatch({ type: "setCurrentField", payload: "toField" });
        dispatch({ type: "setTo", payload: cellDate });
        // dispatch({ type: "closeCalendar" });
      } else {
        dispatch({ type: "setCurrentField", payload: "fromField" });
        dispatch({ type: "setFrom", payload: cellDate });
      }

      /* if (state.currentField == "fromField") {
        dispatch({ type: "setFrom", payload: cellDate})
        dispatch({ type: "closeModal" });
      } else {
        dispatch({ type: "setTo", payload: cellDate})
        dispatch({ type: "closeModal" });
      } */
    };

    if (isBetween && isSameMonth(state.currentDate, cellDate))
      return (
        <div className="cell-container">
          <div className="cell isBetween" onClick={handleCellClick}>
            {cellDate.getDate()}
          </div>
        </div>
      );
    if (isSelected && isSameMonth(state.currentDate, cellDate))
      return (
        <div className="cell-container">
          <div className="cell selected">{cellDate.getDate()}</div>
        </div>
      );
    if (isBefore(cellDate, new Date()) && !isSameDay(cellDate, new Date()))
      return (
        <div className="cell-container">
          <div className="cell disabled">{cellDate.getDate()}</div>
        </div>
      );
    if (!isSameMonth(state.currentDate, cellDate))
      return (
        <div className="cell-container">
          <div className="empty-cell"></div>
        </div>
      );
    if (
      state.from &&
      state.currentField == "toField" &&
      (isBefore(cellDate, state.from) || isSameDay(cellDate, state.from))
    ) {
      return (
        <div className="cell-container">
          <div className="cell disabled">{cellDate.getDate()}</div>
        </div>
      );
    }
    if (
      state.to &&
      state.currentField == "fromField" &&
      (isAfter(cellDate, state.to) || isSameDay(cellDate, state.to))
    ) {
      return (
        <div className="cell-container">
          <div className="cell disabled">{cellDate.getDate()}</div>
        </div>
      );
    }

    return (
      <div className="cell-container">
        <div className="cell" onClick={handleCellClick}>
          {cellDate.getDate()}
        </div>
      </div>
    );
  }

  function cellComponentTo({ cellDate, isSelected, isBetween }) {
    let handleCellClick = () => {
      if (state.from && state.to) {
        dispatch({ type: "setCurrentField", payload: "fromField" });
        dispatch({ type: "setFrom", payload: null });
        dispatch({ type: "setTo", payload: null });
      } else if (state.from && isBefore(state.from, cellDate)) {
        dispatch({ type: "setCurrentField", payload: "toField" });
        dispatch({ type: "setTo", payload: cellDate });
        // dispatch({ type: "closeCalendar" });
      } else {
        dispatch({ type: "setCurrentField", payload: "fromField" });
        dispatch({ type: "setFrom", payload: cellDate });
      }
      /* if (state.currentField == "fromField") {
        dispatch({ type: "setFrom", payload: cellDate})
        dispatch({ type: "closeModal" });
      } else {
        dispatch({ type: "setTo", payload: cellDate})
        dispatch({ type: "closeModal" });
      } */
    };

    if (isBetween && isSameMonth(addMonths(state.currentDate, 1), cellDate))
      return (
        <div className="cell-container">
          <div className="cell isBetween" onClick={handleCellClick}>
            {cellDate.getDate()}
          </div>
        </div>
      );
    if (isSelected && isSameMonth(addMonths(state.currentDate, 1), cellDate))
      return (
        <div className="cell-container">
          <div className="cell selected">{cellDate.getDate()}</div>
        </div>
      );
    if (isBefore(cellDate, new Date()) && !isSameDay(cellDate, new Date()))
      return (
        <div className="cell-container">
          <div className="cell disabled">{cellDate.getDate()}</div>
        </div>
      );
    if (!isSameMonth(addMonths(state.currentDate, 1), cellDate))
      return (
        <div className="cell-container">
          <div className="empty-cell"></div>
        </div>
      );
    if (
      state.from &&
      state.currentField == "toField" &&
      (isBefore(cellDate, state.from) || isSameDay(cellDate, state.from))
    ) {
      return (
        <div className="cell-container">
          <div className="cell disabled">{cellDate.getDate()}</div>
        </div>
      );
    }
    if (
      state.to &&
      state.currentField == "fromField" &&
      (isAfter(cellDate, state.to) || isSameDay(cellDate, state.to))
    ) {
      return (
        <div className="cell-container">
          <div className="cell disabled">{cellDate.getDate()}</div>
        </div>
      );
    }

    return (
      <div className="cell-container">
        <div className="cell" onClick={handleCellClick}>
          {cellDate.getDate()}
        </div>
      </div>
    );
  }

  return (
    <>
      <Position isMobile={isMobile} ref={ref}>
        <CalendarWrapper>
          <Calendar
            fromDate={state.from}
            toDate={state.to}
            onPrev={onClick}
            onNext={isMobile ? onClick : null}
            date={state.currentDate}
            renderCell={cellComponentFrom}
          />
          {!isMobile && (
            <Calendar
              fromDate={state.from}
              toDate={state.to}
              onNext={onClick}
              date={addMonths(state.currentDate, 1)}
              renderCell={cellComponentTo}
            />
          )}
        </CalendarWrapper>
        <FlexBox>
          <Button onClick={() => dispatch({ type: "closeCalendar" })}>
            Lukk
          </Button>
        </FlexBox>
      </Position>
    </>
  );
}

let FlexBox = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: ${(props) => (props.p ? `${props.p}px` : "0px")};
`;

let Button = styled.button`
  padding: 5px 10px;
  margin: 10px;
  background: transparent;
  border: 1px solid #d8d8d8;
  opacity: 0.8;
  &:hover {
    color: white;
    cursor: pointer;
    background-color: #8f6060;
  }
`;

export default DoubleCalendar;

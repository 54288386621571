import "./Plugin.css";
import Modal from "./Modal";
import React, { useEffect, useReducer } from "react";
import {
  isSameMonth,
  format,
  isBefore,
  isAfter,
  isSameDay,
  differenceInDays,
} from "date-fns";
import nb from "date-fns/locale/nb";
import DoubleCalendar from "./DoubleCalendar";
import { useWindowSize } from "@react-hook/window-size";
import styled from "styled-components";

let reducer = (state, action) => {
  switch (action.type) {
    case "setFrom":
      return { ...state, from: action.payload };
    case "setTo":
      return { ...state, to: action.payload };
    case "setRoom":
      return { ...state, room: action.payload };
    case "setCurrentDate":
      return { ...state, currentDate: action.payload };
    case "openModal":
      return { ...state, isSelecting: true };
    case "closeModal":
      return { ...state, isSelecting: false };
    case "openCalendar":
      return { ...state, isCalendarOpen: true };
    case "closeCalendar":
      return { ...state, isCalendarOpen: false };
    case "setCurrentField":
      return { ...state, currentField: action.payload };
    case "setUrlSearchParams":
      return { ...state, urlSearchParams: action.payload };
  }
};

function Plugin({ date, rooms }) {
  let [state, dispatch] = useReducer(reducer, {
    currentDate: new Date(),
    from: null,
    to: null,
    room: null,
    isSelecting: false,
    currentField: null,
    urlSearchParams: "",
    isCalendarOpen: false,
  });
  let [width, _] = useWindowSize();
  let isMobile = width <= 700;

  useEffect(() => {
    let formData = new FormData();

    if (state.from)
      formData.append(
        "checkIn",
        format(state.from, "yyyy-MM-dd", { locale: nb })
      );
    if (state.to)
      formData.append(
        "checkOut",
        format(state.to, "yyyy-MM-dd", { locale: nb })
      );
    /* if (state.room) formData.append('webProductId', JSON.stringify([state.room])); */

    dispatch({
      type: "setUrlSearchParams",
      payload: new URLSearchParams(formData).toString(),
    });
  }, [state.from, state.to, state.room]);

  let handleDateSelectorClick = () => {
    dispatch({ type: "openCalendar" });

    /* if (state.from && state.to) {
      dispatch({ type: "setCurrentField", payload: "fromField" });
      dispatch({ type: "setFrom", payload: null });
      dispatch({ type: "setTo", payload: null });
    } else if (state.from) {
      dispatch({ type: "setCurrentField", payload: "toField" });
    } else {
      dispatch({ type: "setCurrentField", payload: "fromField" });
    } */
  };

  /* let handleDateSelectorToClick = () => {
    dispatch({ type: "openModal" });
    dispatch({ type: "setCurrentField", payload: "toField" });
  }; */

  let handleOnClose = () => dispatch({ type: "closeCalendar" });
  let handleSearch = () => {
    if (state.room) {
      window.open(
        `https://reservations.visbook.com/8112/search?lang=no&${state.urlSearchParams}&webproductid=[${state.room}]`,
        "_blank"
      );
    } else {
      window.open(
        `https://reservations.visbook.com/8112/search?lang=no&${state.urlSearchParams}`,
        "_blank"
      );
    }
  };
  let handleDateClick = (date) =>
    dispatch({ type: "setCurrentDate", payload: date });
  let handleRoomSelection = ({ target }) =>
    dispatch({
      type: "setRoom",
      payload: target.value == "Velg rom" ? null : target.value,
    });

  return (
    <div className="booking">
      {state.isCalendarOpen && !isMobile && (
        <DoubleCalendar
          dispatch={dispatch}
          state={state}
          onClick={handleDateClick}
        />
      )}
      <DateSelector
        dateFrom={state.from}
        dateTo={state.to}
        labelFrom="Ankomst"
        labelTo="Avreise"
        onClick={handleDateSelectorClick}
      />
      {/* <DateSelector date={state.to} label="Avreise" onClick={handleDateSelectorClick}  /> */}
      <select className="rooms" onChange={handleRoomSelection}>
        <option>Velg rom</option>
        {rooms.map((r) => (
          <option value={r.id}>{r.name}</option>
        ))}
      </select>
      <div className="button booking-field w-button" onClick={handleSearch}>
        SØK
      </div>
      <Modal onClose={handleOnClose} isOpen={state.isCalendarOpen && isMobile}>
        <DoubleCalendar
          dispatch={dispatch}
          state={state}
          onClick={handleDateClick}
        />
        {/* <Calendar fromDate={state.from} toDate={state.to} onNext={handleDateClick} onPrev={handleDateClick} date={state.currentDate} renderCell={cellComponent} /> */}
      </Modal>
    </div>
  );
}

function DateSelector({ dateFrom, dateTo, labelFrom, labelTo, onClick }) {
  return (
    <div className="checkin" onClick={onClick}>
      {dateFrom
        ? capitalizeFirstLetter(format(dateFrom, "PPPP", { locale: nb }))
        : labelFrom}
      {" - "}
      {dateTo
        ? capitalizeFirstLetter(format(dateTo, "PPPP", { locale: nb }))
        : labelTo}
    </div>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default Plugin;
